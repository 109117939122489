import React from 'react'


export default function NotesEmailModal (props){

    const resetRef = React.useRef(null)
    const reset1Ref = React.useRef(null)
    const reset2Ref = React.useRef(null)

    function exit (){

        props.handleClick1(prev => {return !prev})

        resetRef.current.value = "";
        reset1Ref.current.value = "";
        reset2Ref.current.value = "";
    }

    function save(){

        const addNotes = {subject: reset1Ref.current.value, content: resetRef.current.value}
        props.handleClick2(addNotes)
        
    }

    function saveandSend(){

        const addNotes = {subject: reset1Ref.current.value, content: resetRef.current.value}
        const email = reset2Ref.current.value
        const subject = reset1Ref.current.value;
        const content = resetRef.current.value

        props.handleClick2(addNotes)
        window.open(`mailto:${email}?subject=${subject}&body=${content}`);

    }
    
    const RenderNotes = props.props3.map(prev => {
        return (
        <div className="singleNotes">
            <div>
                <h2> {prev.subject}</h2>
            </div>
            <div>
                <p> {prev.content}</p>
            </div>
        </div>
        )
    })




    return(
        <div className={props.props1 ? "activemodal" : "inactivemodal"}>
            <div>

                <p className="exitmodal" onClick={exit} > ✖ </p>

                <h1> {props.props2.currentDay.charAt(0).toUpperCase() + props.props2.currentDay.slice(1)} - {props.props2.currentSuite.charAt(0).toUpperCase() + props.props2.currentSuite.slice(1)} - {props.props2.currentRoom.charAt(0).toUpperCase() + props.props2.currentRoom.slice(1)} Notes </h1>

                <div id='subjectemail'>

                    <input
                        type="text"
                        placeholder="Subject"
                        ref={reset1Ref}
    
                    />

                    <input
                        type="email"
                        placeholder="Email"
                        ref={reset2Ref}
                    />

                </div>


                <textarea
                placeholder="add a Comment"
                ref={resetRef}
                className="notesfield"
                >
                </textarea>

                <h1> Past Notes </h1>

                <div className='pastNotesdiv'>


                {RenderNotes}


                </div>

                <div className="modalbutton">

                    <div onClick={save}>
                        <h2> Save </h2>
                    </div>

                    <div onClick={saveandSend}>
                        <h2> Save & Send </h2>
                    </div>

                </div>

            </div>
        </div>


        
    )
}