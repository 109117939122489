import React from "react"

import PumpDataPlate from "./images/IMG_3762.jpeg"

export default function PumpForm (props){


//add information button pump
    //add photo for data plate
    //add photo for issue (DT, data trouble)


    
    const [pumpState,setPumpState] = React.useState(props.props)

    const pumpHandler = (event, pumpNum, property) => {
        
        setPumpState(prevState => {
            const newPumpState = { ...prevState };
            newPumpState[pumpNum][property] =
                event.value === undefined ? event : Number(event.value);
            if (property === "isPumpon"){
                newPumpState[pumpNum].pumpPSI = 0;
            }
            return newPumpState;
        });

    };


    const [infoModal,setInfoModal] = React.useState(false)

    
    return(
        <div id="pumpform">

            <h1>Primary</h1>
            
            <div className="pumpcolumns">

                <div className="CWP">

                <div className="cwptitle">
                    <h2>CWP1</h2> 
                    
                    <h3
                    onClick={() => setInfoModal(prev => !prev)} 
                    >
                     ⓘ
                    </h3>
                </div>
                
                    
                        
                        <div>

                            <div>
                                <div
                                    className={pumpState.primaryCWP1.isPumpon === true ? "switchon" : "switchoff"}
                                    onClick={() => pumpHandler(!pumpState.primaryCWP1.isPumpon,"primaryCWP1","isPumpon")}
                                >
                                    {pumpState.primaryCWP1.isPumpon === true ? "ON" : "OFF"}
                                </div>

                                {pumpState.primaryCWP1.isPumpon && 

                                <div className="pumponinput">
                                    <label htmlFor="cwp1psi"> PSI: </label>
                                    <input 
                                        type="text"
                                        id="cwp1psi"
                                        className="formbar"                                
                                        value={pumpState.primaryCWP1.isPumpon === false ? 0 : pumpState.primaryCWP1.pumpPSI }
                                        onChange={(e) => pumpHandler(e.target,"primaryCWP1","pumpPSI")}
                                    />
                                </div>
                                }

                            </div>

                        </div>

                </div>


                <div className="CWP">
                    
                <div className="cwptitle">
                    <h2>CWP2</h2> 
                    
                    <h3
                    onClick={() => setInfoModal(prev => !prev)} 
                    >
                     ⓘ
                    </h3>
                </div>

                        <div>

                            <div>

                                <div
                                className={pumpState.primaryCWP2.isPumpon === true ? "switchon" : "switchoff"}
                                onClick={() => pumpHandler(!pumpState.primaryCWP2.isPumpon,"primaryCWP2","isPumpon")}
                                >
                                    {pumpState.primaryCWP2.isPumpon === true ? "ON" : "OFF"}
                                </div>

                                {pumpState.primaryCWP2.isPumpon &&
                                    <div className="pumponinput">

                                        <label htmlFor="cwp1psi"> PSI: </label>
                                        <input 
                                            type="text"
                                            id="cwp1psi"
                                            className="formbar"

                                            value={pumpState.primaryCWP2.isPumpon === false ? 0 : pumpState.primaryCWP2.pumpPSI}
                                            onChange={(e) => pumpHandler(e.target,"primaryCWP2","pumpPSI")}
                                        /> 
                                    </div>
                                }
                                
 
                            </div>
                        </div>
 
                </div>

            </div>

            <h1>Secondary</h1>

            <div className="pumpcolumns" >

                <div className="CWP">
                    
                    <div className="cwptitle">
                        <h2>CWP3</h2> 
                        
                        <h3
                        onClick={() => setInfoModal(prev => !prev)} 
                        >
                        ⓘ
                        </h3>
                    </div>

                    <div>


                        <div>

                            <div
                            className={pumpState.secondaryCWP3.isPumpon === true ? "switchon" : "switchoff"}
                            onClick={() => pumpHandler(!pumpState.secondaryCWP3.isPumpon,"secondaryCWP3","isPumpon")}
                            >
                                {pumpState.secondaryCWP3.isPumpon === true ? "ON" : "OFF"}
                            </div>

                            {pumpState.secondaryCWP3.isPumpon &&
                                <div className="pumponinput">
                                    <label htmlFor="cwp3psi"> PSI: </label>
                                    <input 
                                        type="text"
                                        id="cwp3psi"
                                        className="formbar"
                                        value={pumpState.secondaryCWP3.isPumpon === false ? 0 : pumpState.secondaryCWP3.pumpPSI}
                                        onChange={(e) => pumpHandler(e.target,"secondaryCWP3","pumpPSI")}
                                    />
                                </div>
                            }
                       


                        </div>
                        
                        {pumpState.secondaryCWP3.isPumpon &&
                        
                        <div>

                            <label htmlFor="cwp3psi"> VFD Hz: </label>
                            <input 
                                type="text"
                                id="cwp3vfdhz"
                                className="formbar"

                                value={pumpState.secondaryCWP3.isPumpon === false ? 0 : pumpState.secondaryCWP3.vfdHz}
                                onChange={(e) => pumpHandler(e.target,"secondaryCWP3","vfdHz")}
                            />

                        </div>
                        }

                    </div>
                    
                </div>

                <div className="CWP">
                    
                    <div className="cwptitle">
                        <h2>CWP4</h2> 
                        
                        <h3
                        onClick={() => setInfoModal(prev => !prev)} 
                        >
                        ⓘ
                        </h3>
                    </div>

                    <div>

                        <div>

                            <div
                            className={pumpState.secondaryCWP4.isPumpon === true ? "switchon" : "switchoff"}
                            onClick={() => pumpHandler(!pumpState.secondaryCWP4.isPumpon,"secondaryCWP4","isPumpon")}
                            >
                                {pumpState.secondaryCWP4.isPumpon === true ? "ON" : "OFF"}
                            </div>

                            {pumpState.secondaryCWP4.isPumpon &&
                                <div className="pumponinput">

                                    <label htmlFor="cwp4psi"> PSI: </label>
                                    <input 
                                        type="text"
                                        id="cwp4psi"
                                        className="formbar"

                                        value={pumpState.secondaryCWP4.isPumpon === false ? 0 : pumpState.secondaryCWP4.pumpPSI}
                                        onChange={(e) => pumpHandler(e.target,"secondaryCWP4","pumpPSI")}
                                    />

                                </div>
                            }


                        </div>
                        {
                            pumpState.secondaryCWP4.isPumpon &&
                            <div>
                                <label htmlFor="cwp4psi"> VFD Hz: </label>
                                <input 
                                    type="text"
                                    id="cwp4vfdhz"
                                    className="formbar"

                                    value={pumpState.secondaryCWP4.isPumpon === false ? 0 : pumpState.secondaryCWP4.vfdHz}
                                    onChange={(e) => pumpHandler(e.target,"secondaryCWP4","vfdHz")}
                                />
                            </div>
                        }


                    </div>
                    
                </div>

            </div>

            <div className={infoModal ? "pumpdataplate" : "inactivemodal"}>

                <div>

                    <p className="exitmodal"
                    // onClick={exit}
                    onClick={() => setInfoModal(prev => !prev)} 
                    >
                    ✖ 
                    </p>

                    <div>

                        <h1> Data Plate </h1>

                        <img
                            src={PumpDataPlate}
                        />




                    </div>

                </div>

            </div>

        </div>
    )
}