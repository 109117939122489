import React from "react"

export default function ChillerForm (props){

    const chillerArray = ["///","///","110","120","130","140","150","160","///","///"]

    const chillerState = props.props2
    const setChillerState = props.handleClick2


    const [chiller, setChiller] = React.useState(props.props)


    const chillHandler = (event,chiller,chillNum, property) => {
        setChiller(prevState => {
            const newChillState = { ...prevState };
            newChillState[chiller][chillNum][property] =
                event.value === undefined ? event : Number(event.value);
            return newChillState;
        });
    };


return(

    <div id="chillerPage">

        

        <div id="chillerdiv">

            <div id="genNavDiv">
                <div id="genNav">
                    <div
                    onClick={ chillerState > 2 ? () => setChillerState(prev => (prev - 1)) : null }
                    >
                        <h1> ◀ </h1>
                    </div>

                    <div
                    onClick={chillerState > 3 ? () => setChillerState(prev => (prev - 2)) : null}
                    >
                        <h4> {chillerArray[chillerState - 2 ]} </h4>
                    </div>

                    <div
                    onClick={ chillerState > 2 ? () => setChillerState(prev => (prev - 1)) : null }
                    >
                        <h3> {chillerArray[chillerState - 1 ]} </h3>
                    </div>

                    <div>
                        <h1> <b> {chillerArray[chillerState]} </b> </h1>
                    </div>

                    <div 
                    onClick={chillerState < 7 ? () => setChillerState(prev => (prev + 1)) : null}
                    >
                        <h3> {chillerArray[chillerState + 1 ]} </h3>
                    </div>

                    <div
                    onClick={chillerState < 6 ? () => setChillerState(prev => (prev + 2)) : null}
                    >
                        <h4> {chillerArray[chillerState + 2 ]} </h4>
                    </div>

                    <div
                    onClick={chillerState < 7 ? () => setChillerState(prev => (prev + 1)) : null}
                    >
                        <h1> ▶ </h1>
                    </div>

                </div>
            </div>

            <div className="chiller">

                <div className="chillerTitle">
                    <h1> Chiller 1 ⓘ</h1>
                </div>

                <div className="ChillerTablediv">

                    <div>
                        <div>
                            <div>
                                Chiller Status:
                            </div>
                            <div>

                                <div
                                className={chiller[chillerArray[chillerState]].chillerOne.isChillerOn ? "switchon" : "switchoff"}
                                onClick={() => chillHandler(!chiller[chillerArray[chillerState]].chillerOne.isChillerOn,chillerArray[chillerState],"chillerOne","isChillerOn")}
                                >
                                {chiller[chillerArray[chillerState]].chillerOne.isChillerOn === true ? "ON" : "OFF"}
                                </div>

                            </div>
                            
                        </div>

                        <div>
                                <div>
                                Setpoint:
                                </div>
                                <div>
                                    <div className="chillinputnumber">
                                    <input
                                        type="text"
                                        value={chiller[chillerArray[chillerState]].chillerOne.setPoint}
                                        onChange={(e) => chillHandler(e.target,chillerArray[chillerState],"chillerOne","setPoint")}
                                    />
                                    </div>
                                </div>
                        </div>
                    </div>

                    {chiller[chillerArray[chillerState]].chillerOne.isChillerOn === true &&
                    <div>
                        <div>
                            <div>
                            <p> Supply Temp:</p>
                            </div>
                            <div className="chillinputnumber">
                            <input
                                type="text"
                                value={props.props[chillerArray[chillerState]].chillerOne.waterTempSupply}
                                onChange={(e) => chillHandler(e.target,chillerArray[chillerState],"chillerOne","waterTempSupply")}
                            />
                            </div>  
                        </div>

                        <div>
                            <div>
                            <p> Temp Return:</p>
                            </div>
                            <div className="chillinputnumber">
                            <input
                            type="text"
                            value={props.props[chillerArray[chillerState]].chillerOne.watetTempReturn}
                            onChange={(e) => chillHandler(e.target,chillerArray[chillerState],"chillerOne","watetTempReturn")}
                            />
                            </div>  
                        </div>
                    </div>
                    }

                    {chiller[chillerArray[chillerState]].chillerOne.isChillerOn === false &&
                        <div>
                            <div>
                                <div>
                                    <p> Auto:</p>
                                </div>
                                <div className="chillinputcheckbox">
                                    <input
                                        type="checkbox"
                                        checked={chiller[chillerArray[chillerState]].chillerOne.isgeneratorInAuto === true}
                                        onChange={() => chillHandler(!chiller[chillerArray[chillerState]].chillerOne.isgeneratorInAuto,chillerArray[chillerState],"chillerOne","isgeneratorInAuto")}
                            
                                    />
                                </div>
                            </div>

                            <div>
                                <div>
                                    <p> Run Inhibit:</p>
                                </div>
                                <div className="chillinputcheckbox">
                                    <input
                                        type="checkbox"
                                        checked={chiller[chillerArray[chillerState]].chillerOne.isRunInhibitOn === true}
                                        onChange={() => chillHandler(!chiller[chillerArray[chillerState]].chillerOne.isRunInhibitOn === true,chillerArray[chillerState],"chillerOne","isRunInhibitOn")}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                </div>
                
            </div>

            <div className="chiller">

                <div className="chillerTitle">
                    <h1> Chiller 2 ⓘ</h1>
                </div>

                <div className="ChillerTablediv">

                    <div>
                        <div>
                            <div>
                                Chiller Status:
                            </div>
                            <div>

                                <div
                                className={chiller[chillerArray[chillerState]].chillerTwo.isChillerOn ? "switchon" : "switchoff"}
                                onClick={() => chillHandler(!props.props[chillerArray[chillerState]].chillerTwo.isChillerOn,chillerArray[chillerState],"chillerTwo","isChillerOn")}
                                >
                                {chiller[chillerArray[chillerState]].chillerTwo.isChillerOn === true ? "ON" : "OFF"}
                                </div>
                                
                            </div>
                            
                        </div>

                        <div>
                                <div>
                                Setpoint:
                                </div>
                                <div>
                                    <div className="chillinputnumber">
                                    <input
                                        type="text"
                                        value={chiller[chillerArray[chillerState]].chillerTwo.setPoint}
                                        onChange={(e) => chillHandler(e.target,chillerArray[chillerState],"chillerTwo","setPoint")}
                                    />
                                    </div>
                                </div>
                        </div>
                    </div>

                    {chiller[chillerArray[chillerState]].chillerTwo.isChillerOn === true &&
                    <div>
                        <div>
                            <div>
                                <p> Temp Supply:</p>
                            </div>
                            <div className="chillinputnumber">
                                <input
                                    type="text"
                                    value={props.props[chillerArray[chillerState]].chillerTwo.waterTempSupply}
                                    onChange={(e) => chillHandler(e.target,chillerArray[chillerState],"chillerTwo","waterTempSupply")}
                                />
                            </div>

                        </div>

                        <div>
                            <div>
                                <p> Temp Return:</p>
                            </div>

                            <div className="chillinputnumber">
                                <input
                                    type="text"
                                    value={props.props[chillerArray[chillerState]].chillerTwo.watetTempReturn}
                                    onChange={(e) => chillHandler(e.target,chillerArray[chillerState],"chillerTwo","watetTempReturn")}
                                />
                            </div>
                        </div>
                    </div>
                    }

                    { chiller[chillerArray[chillerState]].chillerTwo.isChillerOn === false &&
                    <div>
                        <div>
                            <div>
                                <p> Auto:</p>
                            </div>
                            
                            <div className="chillinputcheckbox">
                                <input
                                    type="checkbox"
                                    checked={chiller[chillerArray[chillerState]].chillerTwo.isgeneratorInAuto === true}
                                    onChange={() => chillHandler(!chiller[chillerArray[chillerState]].chillerTwo.isgeneratorInAuto,chillerArray[chillerState],"chillerTwo","isgeneratorInAuto")}
                        
                                />
                            </div>
                        </div>

                        <div>
                            <div>
                                <p> Alarms:</p>
                            </div>

                            <div className="chillinputcheckbox">
                                <input
                                    type="checkbox"
                                    checked={chiller[chillerArray[chillerState]].chillerTwo.isAlarmOn === true}
                                    onChange={() => chillHandler(!chiller[chillerArray[chillerState]].chillerTwo.isAlarmOn === true,chillerArray[chillerState],"chillerTwo","isAlarmOn")}
                                />
                            </div>
                        </div>
                    </div>
                    }

                </div>

            </div>

        </div>

    </div>

)

}