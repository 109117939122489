import React from "react"


export default function DateIntial (props){

    const [general,setgeneral] = React.useState({
        engineer:"",
        week:"",
    })

    const nameSetter = (event) => {

        setgeneral(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })

        props.handleClick(general)

    }

    return (
  
            <div id="dateIntial">

                <h3>Engineer:</h3>

                <input
                type="text"
                onChange={nameSetter}
                value={general.engineer}
                name="engineer"
                
                 />

                 <h3>Date:</h3>

                <input
                type="week"
                onChange={nameSetter}
                value={general.week}
                name="week"
                

                 />

            </div>

    )
}