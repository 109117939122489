import logo from './logo.svg';
import './App.css';

import Head from "./header"
import Mainbody from './main';

function App() {
  
  return (
    <div className="App">
      <header className="App-header">

  
      <Head/>

      <Mainbody/>



      </header>
    </div>
  );
}

export default App;
