import React from "react"

export default function EmailModal (props){

    const resetRef = React.useRef(null)
    const reset1Ref = React.useRef(null)
    const reset2Ref = React.useRef(null)
    const reset3Ref = React.useRef(null)


    const [photoFile, setPhotoFile] = React.useState(null)


    function exit (){

        props.handleClick1(prev => {return !prev})
        resetRef.current.value = "";
        reset1Ref.current.value = "";
        reset2Ref.current.value = "";
        reset3Ref.current.value = "";
    }


    function save (){

        const addPhotos = {subject: reset1Ref.current.value, content: resetRef.current.value, photo: (photoFile)}
        props.handleClick2(addPhotos)

    }

    function saveandSend (){

    const addPhotos = {subject: reset1Ref.current.value, content: resetRef.current.value, photo: (photoFile)}
    props.handleClick2(addPhotos)
    
    const email = reset2Ref.current.value
    const subject = reset1Ref.current.value;
    const content = resetRef.current.value
    window.open(`mailto:${email}?subject=${subject}&body=${content}${photoFile}`);

    }




    const RenderPhotos = props.props3.map(prev => {

        return (
        <div className="imagediv">
            <div>
                <h2> {prev.subject}</h2>
            </div>
            <div>
                <p> {prev.content}</p>
            </div>
            <div>
                <img src={prev.photo}/>
            </div>

        </div>
        )
    })



    function handlerFile (e){
        
        var url = URL.createObjectURL(e.target.files[0]);
        setPhotoFile(url)

    }





    return(
        <div className={props.props1 ? "activemodal" : "inactivemodal"}>
            <div>

            <p className="exitmodal" onClick={exit} > ✖ </p>

            <h1> {props.props2.currentDay.charAt(0).toUpperCase() + props.props2.currentDay.slice(1)} - {props.props2.currentSuite.charAt(0).toUpperCase() + props.props2.currentSuite.slice(1)} - {props.props2.currentRoom.charAt(0).toUpperCase() + props.props2.currentRoom.slice(1)} Photos </h1>

            <div id='subjectemail'>
                <input
                    type="text"
                    placeholder="Subject"
                    ref={reset1Ref}

                />

                <input
                    type="email"
                    placeholder="Email"
                    ref={reset2Ref}
                />
            </div>


            <textarea
                placeholder="add a Comment"
                ref={resetRef}
                className="notesfield"
                >
            </textarea>



            <input
            type="file"
            id="capture"
            accept="image/*,video/*"
            ref={reset3Ref}
            onChange={(e) => handlerFile(e)}
            capture
            />

            <h1> Past Photo </h1>


            <div className='pastNotesdiv'>

            {RenderPhotos}

            </div>





            <div className="modalbutton">

                    <div
                    onClick={save}
                    >
                        <h2> Save </h2>
                    </div>

                    <div
                    onClick={saveandSend}
                    >
                        <h2> Save & Send </h2>
                    </div>

            </div>

            </div>
        </div>
    )
}