import React from "react"




export default function Suitestabs (props){

        return(

            <div id="suitestab" >
    
                <div
                className={props.props === "s110" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("s110","currentSuite")}
                >
                    <h2>110</h2>
                </div>
    
                <div
                className={props.props === "s120" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("s120","currentSuite")}
                >
                    <h2>120</h2>
                </div>
    
                <div
                className={props.props === "s130" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("s130","currentSuite")}
                >
                    <h2>130</h2>
                </div>
    
                <div
                className={props.props === "s140" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("s140","currentSuite")}
                >
                    <h2>140</h2>
                </div>
    
                <div
                className={props.props === "s150" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("s150","currentSuite")}
                >
                    <h2>150</h2>
                </div>
    
                <div
                className={props.props === "s160" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("s160","currentSuite")}
                >
                    <h2>160</h2>
                </div>
    
                <div
                className={props.props === "yard" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("yard","currentSuite")}
                >
                    <h2>Yard</h2>
                </div>
    
                {/* <div
                className={props.props === "generator" ? "activeSuite" : "inactiveSuite"}
                onClick={() => props.handleClick("generator","currentSuite")}
                >
                    <h2>Generator</h2>
                </div> */}
    
            </div>
    
        )
    }