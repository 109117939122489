import React from "react"


export default function DayPanel (props){

    return (
        
            <div id="dayselector">

                <div
                className={props.props === "monday" ? "activeday" : "inactiveday"}
                onClick={() => props.handleClick("monday","currentDay")}
                >
                    <h1> Mon </h1>
                </div>

                <div
                className={props.props === "tuesday" ? "activeday" : "inactiveday"}
                onClick={() => props.handleClick("tuesday","currentDay")}
                >
                    <h1> Tue </h1>
                </div>

                <div
                className={props.props === "wednesday" ? "activeday" : "inactiveday"}
                onClick={() => props.handleClick("wednesday","currentDay")}
                >
                    <h1> Wed </h1>
                </div>

                <div
                className={props.props === "thursday" ? "activeday" : "inactiveday"}
                onClick={() => props.handleClick("thursday","currentDay")}
                >
                    <h1> Thu</h1>
                </div>

                <div
                className={props.props === "friday" ? "activeday" : "inactiveday"}
                onClick={() => props.handleClick("friday","currentDay")}
                >
                    <h1> Fri </h1>
                </div>

            </div>
    )
}