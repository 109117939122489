import React from "react"


import DayPanel from "./daypanel"
import SuitesTab from "./suites.jsx"
import RoomsTab from "./roomstab.jsx"
import DateIntial from "./dateIntial.jsx"
import PumpForm from "./pumpform.jsx"
import DataRoomForm from "./dataRoomForm"
import UPSRoomForm from "./upsRoomForm"
import GeneratorTab from "./generatorTab"
import ChillerForm from "./chiller.js"
import NotesEmailModal from "./notesemailmodal"
import EmailModal from "./EmailModal.js"

import CatNoNo from "./images/catshakinghead.gif"
import CamaraIcon from "./images/photography-photographer-computer-icons-clip-art-photography-free-icon-4391ba818bcfd9014fcbb86dd5133c87.png"
import NotesIcon from "./images/pngkey.com-notes-icon-png-3491656.png"

export default function Mainbody (){

    const [weeklyForm,setWeeklyForm] = React.useState({
        generalInfo:
            {
            engineer:"",
            week:""
        },
        weekInfo:{
            monday:{
                s110:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: true,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    }, 
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:true,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:true,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        PDU:{
                            pduA1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },           
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s120:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        PDU:{
                            pduA1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s130:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        PDU:{
                            pduA1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                kwTotal:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s140:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        PDU:{
                            pduA1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s150:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        PDU:{
                            pduA1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s160:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        PDU:{
                            pduA1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduA4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB1:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB2:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB3:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                            pduB4:{
                                ampA:0,
                                ampB:0,
                                ampC:0,
                                kwA:0,
                                kwB:0,
                                kwC:0,
                                isthereAlarm:false,
                                addpduAlarmPhoto: "",
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                yard:{
                    generator:{
                        110:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        130:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        140:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        150:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        160:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        PBB:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:false,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    chiller:{
                        110:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                        },
                        120:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        130:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        140:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        150:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        160:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
            },
            tuesday:{
                s110:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },           
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },          
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s120:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s130:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s140:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },          
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s150:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s160:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                yard:{
                    generator:{
                        110:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        130:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        140:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        150:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        160:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        PBB:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:false,
                            generatorBatteryChargerVoltage:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    chiller:{
                        110:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                        },
                        120:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        130:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        140:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        150:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        160:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
            },
            wednesday:{
                s110:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },  
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },          
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s120:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s130:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s140:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },           
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },           
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s150:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s160:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                yard:{
                    generator:{
                        110:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        130:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        140:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        150:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        160:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        PBB:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:false,
                            generatorBatteryChargerVoltage:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    chiller:{
                        110:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                        },
                        120:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        130:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        140:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        150:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        160:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
            },
            thursday:{
                s110:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s120:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s130:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s140:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s150:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s160:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                yard:{
                    generator:{
                        110:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        130:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        140:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        150:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        160:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        PBB:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:false,
                            generatorBatteryChargerVoltage:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    chiller:{
                        110:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                        },
                        120:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        130:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        140:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        150:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        160:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
            },
            friday:{
                s110:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:
                        {
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s120:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s130:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            isUpsLightsNormal:false,
                            redundancyMeter:0,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s140:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s150:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                s160:{
                    pumpRoom:{
                        primaryCWP1:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        primaryCWP2:{
                            isPumpon: false,
                            pumpPSI: 0,
                        },
                        secondaryCWP3:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        secondaryCWP4:{
                            isPumpon: false,
                            pumpPSI: 0,
                            vfdHz:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    dataRoom:{
                        fireAndRoomInspection:{
                            isFirePanelNormal:false,
                            addPhotoOfFirePane:"",
                            hasDoneRoomInspection:false,
                            addPhotoOfRoom:"",
                        },
                        crates:{
                            c1:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c2:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c3:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c4:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c5:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c6:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c7:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c8:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c9:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c10:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c11:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c12:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c13:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c14:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c15:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                            c16:{
                                onStatus:false,
                                crateTemp:0,
                                crateHumidity:0,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    upsRoom:{
                        upsRoomStatus:{
                            hasHadRoominspection:false,
                            addPhotoOfRoomInspection:"",
                            isUpsLightsNormal:false,
                        },
                        upsSystemA:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        upsSystemB:{
                            isYesSccLZeroActiveAlarmsAndBypassAvailable:false,
                            isYesSccLOutputAmperagePerPhaseAreBelowNormal:false,
                            SccLOutputPowerKVA:0,
                            isYesUpsModuleL1ZeroActiveAlarms:false,
                            isYesModuleL1OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL1OutputPowerKVA:0,
                            isYesModuleL2ZeroActiveAlarms:false,
                            isYesModuleL2OutputAmeragePerPhaseAreBelowNormal:false,
                            moduleL2OutputPowerKVA:0,
                            voltageAtBatteryScreenL1:0,
                            voltageAtBatteryScreenL2:0,
                            cabinetTempL1:0,
                            cabinetTempL2:0,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },
                yard:{
                    generator:{
                        110:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        120.2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        130:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        140:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        150:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        160:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG1:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        RG2:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:0,
                            generatorBatteryChargerVoltage:0,
                            generatorStartCount:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        PBB:{
                            hasdoneroomInspection:false,
                            isgeneratorInAuto:false,
                            generatorBlockTemp:false,
                            generatorBatteryChargerVoltage:0,
                            generatorTotalHoursRan:0,
                            tankFuelLevel:0,
                            isFuelPolisherWorking:false,
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                    chiller:{
                        110:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                        },
                        120:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        130:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        140:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        150:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        160:{
                            chillerOne:{
                                isChillerOn:true,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                                isAlarmOn:false,
                            },
                            chillerTwo:{
                                isChillerOn:false,
                                setPoint:0,
                                waterTempSupply:0,
                                watetTempReturn:0,
                                isAlarmOn:false,
                                isgeneratorInAuto:false,
                                isRunInhibitOn:false,
                            isAlarmOn:false,
                            },
                        },
                        other:{
                            notes:[],
                            photos:[],
                        },
                    },
                },


            },
        },
        })





    const nameSetter = (value) => {
        setWeeklyForm(prev => {
            return{
                ...prev, generalInfo: value
                
            }
        }) 
        
    }

    const [currentState, setCurrentState] = React.useState(
        {
        currentDay:"monday",
        currentSuite:"s110",
        currentRoom:"pumpRoom",
        }
    )

    // currentDay:"monday",
    // currentSuite:"s110",
    // currentRoom:"pumpRoom",



    const statusToggler = (value, name) =>{
        setCurrentState(prev => {
            return{
                ...prev, [name]: value
            }
        } )
    }



    const day = currentState.currentDay; 
    const suite = currentState.currentSuite;
    const room = currentState.currentRoom;



    const pumpHandler = (state) => {

        // setWeeklyForm(prev => {
        //     const newWeekInfo = { ...prev.weekInfo };
        //     newWeekInfo[day][suite][room] = state;
        //     return { ...prev, weekInfo: newWeekInfo };
        //   });

    }

    const dataHandler = (event,crate,property) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite][room].crates[crate][property] = event.value == undefined ? event : event.value;
            return { ...prev, weekInfo: newWeekInfo };
          });

    }

    const dataHandler2 = (event,property) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite][room].fireAndRoomInspection[property] = event.value == undefined ? event : event.value;
            return { ...prev, weekInfo: newWeekInfo };
          });

    }

    const pduhandler = (event,pdu,property) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo.monday[currentState.currentSuite].dataRoom.PDU[pdu][property] = event.value == undefined ? event : event.value;
            return { ...prev, weekInfo: newWeekInfo };
          });

    }

    const upshandler = (event,ups,property) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite][room][ups][property] = event.value == undefined ? event : event.value;
            return { ...prev, weekInfo: newWeekInfo };
          });
    }

    const crah1516Handler = (event,crate,property) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite].dataRoom.crates[crate][property] = event.value == undefined ? event : event.value;
            return { ...prev, weekInfo: newWeekInfo };
          });

    }

    const genhandler = (event,genNumber,property) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite][room][genNumber][property] = event.value == undefined ? event : event.value;
            return { ...prev, weekInfo: newWeekInfo };
          });
    }

    const chillerHandler = (event,chiller,chillerNUM,property) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite][room][chiller][chillerNUM][property] = event.value == undefined ? event : event.value;
            return { ...prev, weekInfo: newWeekInfo };
          });
    }

    const notesHandler = (addnotes) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite][room].other.notes = [...newWeekInfo[day][suite][room].other.notes,
              addnotes
            ];
            return { ...prev, weekInfo: newWeekInfo };
          });

    }

    const photosHandler = (addphotos) => {

        setWeeklyForm(prev => {
            const newWeekInfo = { ...prev.weekInfo };
            newWeekInfo[day][suite][room].other.photos = [...newWeekInfo[day][suite][room].other.photos,
            addphotos
            ];
            return { ...prev, weekInfo: newWeekInfo };
          });

    }

    const [notesmodal, setNotesModal] = React.useState(false)
    const [camaramodal, setCamaraModal] = React.useState(false)
    const [crateState, setCrateState] = React.useState(1)
    const [pduState, setpduState] = React.useState("pduA1")
    const [systemState, setSystemState] = React.useState("upsSystemA")
    const [genState,setGenState] = React.useState(6)
    const [chillerState,setChillerState] = React.useState(6)



    function RoomRender (){

    if (suite === "yard" & room === "generator"){
        return(
            <GeneratorTab
                props={day}
                props2={weeklyForm.weekInfo[day][suite][room]}
                handleClick={genhandler}
                props3={genState}
                handleClick2={setGenState}
            /> 
        )
    } else if (suite === "yard" & room === "chiller"){
        return(
            <ChillerForm
             props={weeklyForm.weekInfo[day][suite][room]}
             handleClick={chillerHandler}
             props2={chillerState}
             handleClick2={setChillerState}
            />
         )
    } else if (suite[0] === "s" & room === "pumpRoom"){
        return(
                <PumpForm
                    props={weeklyForm.weekInfo[day][suite][room]}
                    // handleClick={pumpHandler}
                />
        )
    } else if (suite[0] === "s" & room === "dataRoom"){
        return(
            <DataRoomForm
                props={weeklyForm.weekInfo}
                props2={currentState}
                // handleClick={dataHandler}
                // handleClick2={pduhandler}
                // handleClick3={dataHandler2}
                props3={crateState}
                handleClick4={setCrateState}
                props4={pduState}
                handleClick5={setpduState}
            />
        )
    } else if (suite[0] === "s" & room === "upsRoom"){
        return(
            <UPSRoomForm
                props={weeklyForm.weekInfo[day][suite][room]}
                // handleClick={upshandler}
                props2={weeklyForm.weekInfo[day][suite].dataRoom.crates}
                props4={suite}
                // handleClick2={crah1516Handler}
                props5={systemState}
                handleClick3={setSystemState}
            />
        )
    } else {
        return(
        
        <div id="error">
            <div>
                <h1>⬆️</h1>
                <h1> Please pick a Room </h1>
            </div>
            {/* <img src={CatNoNo}/> */}
        </div>
        
       )
    }
        
    }


    let roomTitle;

    if (room === "pumpRoom"){
        roomTitle = "Pump Room"
    } else if (room === "dataRoom"){
        roomTitle = "Data Room"
    } else if (room === "upsRoom"){
        roomTitle = "UPS Room"
    }






        return(
            <div>
    
                <div id="daypanel">

                <DayPanel
                    props={currentState.currentDay}
                    handleClick={statusToggler}
                />

                <DateIntial
                    props={weeklyForm}
                    handleClick={nameSetter}
                />

                </div>

            
                <div id="suitespanel">
        
                    <SuitesTab
                        props={currentState.currentSuite}
                        handleClick={statusToggler}
                    />
        
                    <div id="suitepage">
                        


                        {
                        
                        <RoomsTab
                            props={currentState}
                            handleClick={statusToggler}
                        />

                        }



                        {suite[0] === "s" &&
                        <div id="roomnotescamara">

                            <img src={NotesIcon} id="notes" onClick={() => setNotesModal(prev => !prev)}/>

                            <h1 id="pagestatus"> {currentState.currentDay.charAt(0).toUpperCase() + currentState.currentDay.slice(1)} > Suite 
                            { currentState.currentSuite.slice(1)} > {roomTitle}</h1>
                            
                            <img src={CamaraIcon} id="camara" onClick={() => setCamaraModal(prev => !prev)}/>

                            {room[5] === "o" &&
                            <NotesEmailModal
                            props1={notesmodal}
                            handleClick1={setNotesModal}
                            props2={currentState}
                            handleClick2={notesHandler}
                            props3={weeklyForm.weekInfo[day][suite][room].other.notes}
                            />
                            }
                            
                            {room[5] === "o" &&
                            <EmailModal
                            props1={camaramodal}
                            handleClick1={setCamaraModal}
                            props2={currentState}
                            handleClick2={photosHandler}
                            props3={weeklyForm.weekInfo[day][suite][room].other.photos}
                            />
                            }
                            
                            
                        </div>
                        }

                        




                        {/* <input
                            type="file"
                            accept="image/*"
                        capture/> */}



                        

                        <RoomRender />







                        


                    </div>
        
                </div>
    
            </div>
        )
    }



//to do list
    //VFD Hz have larger input width size
    //remove   addPhotoOfFirePane:"" &addPhotoOfRoom:"", addpduAlarmPhoto
    //fix dataRoom props without key error
    //connect ups room Status inputs
    //concern i think it only save state of rooms (like ups and pump)
    //when its switches rooms, for the last room recorded might not get saved(since it doesnt change rooms)
    //crah 15 & 16 (on ups room) need on and off switches for isStatusON