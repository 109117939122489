import React from "react"



export default function UPSRoomForm (props){

    const systemState = props.props5
    const setSystemState = props.handleClick3


    const [upsState, setupsState] = React.useState(props.props)
    const [crahState,setcrah] = React.useState(props.props2)



    const upshandler = (event,ups,property) => {

        setupsState(prev => {
            const newupsState = { ...prev };
            newupsState[ups][property] = event.value == undefined ? event : Number(event.value);
            return { ...prev, newupsState };
          });

    }

    const crahHandler = (target, crateNum, crateProp) => {
        let newCrahState = {...crahState};
        newCrahState[crateNum] = {...newCrahState[crateNum], [crateProp]: Number(target.value)};
        setcrah(newCrahState);
      }




    return(
        
        <div id="upsroomdiv">
        
        <div id="upsstatus1">
            
            <div id="USPstatus">

                <div>
                    <h1>Status:</h1>
                </div>

                <div>
                    <p>Room inspection:</p>
                    <input
                    type="checkbox"
                    className="UPScheckboxes"
                    checked={upsState.upsRoomStatus.hasHadRoominspection == true}
                    onChange={() => upshandler(!upsState.upsRoomStatus.hasHadRoominspection,"upsRoomStatus","hasHadRoominspection")}
                    />
                </div>

                <div>
                    <p>UPS lights normal:</p>
                    <input
                    type="checkbox"
                    className="UPScheckboxes"
                    checked={upsState.upsRoomStatus.isUpsLightsNormal == true}
                    onChange={() => upshandler(!upsState.upsRoomStatus.isUpsLightsNormal,"upsRoomStatus","isUpsLightsNormal")}
                    />
                </div>

                { props.props4[2] < 4 && 
                
                <div id="RMeter">
                    <p>Redundancy meter V:</p>
                    <input
                        type="text"
                        id="redundancyMeter"
                        value={upsState.upsRoomStatus.redundancyMeter}
                        onChange={(e) => upshandler(e.target,"upsRoomStatus","redundancyMeter")}
                    />
                </div>
                }



            </div>

        </div>
            
        <div id="upssystem">

            <div id="upsSystemSwitch">
                <h1> UPS System ⓘ </h1>
                <ul>
                    <li
                    
                    className={systemState === "upsSystemA" ? "selectedups" : "unselectedups"}
                    onClick={() => setSystemState("upsSystemA")}
                    > A </li>
                    <li
                    className={systemState === "upsSystemB" ? "selectedups" : "unselectedups"}
                    onClick={() => setSystemState("upsSystemB")}
                    > B </li>
                </ul>

            </div>
        
            <div id="mainupsSystem">

                <div>
                    <div>
                        <p> Module <b>{systemState[9]}1</b> zero active alarms: </p>

                        <div className="UPSradio">

                            <div
                            className={upsState[systemState].isYesUpsModuleL1ZeroActiveAlarms === true ? "switchon" : "switchoff"}
                            onClick={() => upshandler(!upsState[systemState].isYesUpsModuleL1ZeroActiveAlarms,[systemState],"isYesUpsModuleL1ZeroActiveAlarms")}
                            >
                                {upsState[systemState].isYesUpsModuleL1ZeroActiveAlarms === true ? "ON" : "OFF"}
                            </div>

                        </div>

                    </div>

                    <div>

                        <p>Module <b>{systemState[9]}2</b> zero active alarms:</p>
                        
                        <div className="UPSradio">

                            <div
                            className={upsState[systemState].isYesModuleL2ZeroActiveAlarms === true ? "switchon" : "switchoff"}
                            onClick={() => upshandler(!upsState[systemState].isYesModuleL2ZeroActiveAlarms,[systemState],"isYesModuleL2ZeroActiveAlarms")}
                            >
                                {upsState[systemState].isYesModuleL2ZeroActiveAlarms === true ? "ON" : "OFF"}
                            </div>

                        </div>

                    </div>
                </div>

                <div>
                    <div>
                        <p>Module <b>{systemState[9]}1</b> output amerage per phase are below normal:</p>

                            <div className="UPSradio">

                                <div
                                className={upsState[systemState].isYesModuleL1OutputAmeragePerPhaseAreBelowNormal === true ? "switchon" : "switchoff"}
                                onClick={() => upshandler(!upsState[systemState].isYesModuleL1OutputAmeragePerPhaseAreBelowNormal,[systemState],"isYesModuleL1OutputAmeragePerPhaseAreBelowNormal")}
                                >
                                {upsState[systemState].isYesModuleL1OutputAmeragePerPhaseAreBelowNormal === true ? "ON" : "OFF"}
                                </div>

                            </div>

                    </div>

                    <div>
                        <p>Module <b>{systemState[9]}2</b> output amerage per phase are below normal:</p>

                        <div className="UPSradio">

                            <div
                                className={upsState[systemState].isYesModuleL2OutputAmeragePerPhaseAreBelowNormal === true ? "switchon" : "switchoff"}
                                onClick={() => upshandler(!upsState[systemState].isYesModuleL2OutputAmeragePerPhaseAreBelowNormal,[systemState],"isYesModuleL2OutputAmeragePerPhaseAreBelowNormal")}
                                >
                                {upsState[systemState].isYesModuleL2OutputAmeragePerPhaseAreBelowNormal === true ? "ON" : "OFF"}
                            </div>

                        </div>

                    </div>
                </div>

                <div>

                    <div>

                        <p>Module <b>{systemState[9]}1</b> output power KVA: </p>
                        <input
                            type="text"
                            className="UPSnumbers"
                            value={upsState[systemState].moduleL1OutputPowerKVA}
                            onChange={(e) => upshandler(e.target,[systemState],"moduleL1OutputPowerKVA")}

                        />

                    </div>

                    <div>

                        <p>Module <b>{systemState[9]}2</b> output power KVA:</p>
                        <input
                            type="text"
                            className="UPSnumbers"
                            value={upsState[systemState].moduleL2OutputPowerKVA}
                            onChange={(e) => upshandler(e.target,[systemState],"moduleL2OutputPowerKVA")}

                        />

                    </div>

                </div>

                <div>
                    
                    <div>
                        <p>Voltage at battery screen <b>{systemState[9]}1</b>:</p>
                        <input
                            type="text"
                            className="UPSnumbers"
                            value={upsState[systemState].voltageAtBatteryScreenL1}
                            onChange={(e) => upshandler(e.target,[systemState],"voltageAtBatteryScreenL1")}

                        />
                    </div>

                    <div>
                        <p>Voltage at battery screen <b>{systemState[9]}2</b>:</p>
                        <input
                            type="text"
                            className="UPSnumbers"
                            value={upsState[systemState].voltageAtBatteryScreenL2}
                            onChange={(e) => upshandler(e.target,[systemState],"voltageAtBatteryScreenL2")}

                        />
                    </div>

                </div>

                <div>

                    <div>

                        <p>Cabinet Temp <b>{systemState[9]}1</b>:</p>
                        <input
                            type="text"
                            className="UPSnumbers"
                            value={upsState[systemState].cabinetTempL1}
                            onChange={(e) => upshandler(e.target,[systemState],"cabinetTempL1")}

                        />
                        
                    </div>

                    <div>

                        <p>Cabinet Temp <b>{systemState[9]}2</b>:</p>
                        <input
                            type="text"
                            className="UPSnumbers"
                            value={upsState[systemState].cabinetTempL2}
                            onChange={(e) => upshandler(e.target,[systemState],"cabinetTempL2")}
                        />

                    </div>

                </div>


            </div>

        </div>

        <div id="inline1516">

            <div id="crates1516">

                <ul>
                    <li>
                        CRAH
                    </li>
                    <li>
                        #15
                    </li>
                </ul>

                <ul>
                    <li>
                        Temp
                    </li>

                    <li className="crate1516input">
                        <input
                            type="text"
                            value={crahState.c15.crateTemp}
                            onChange={(e) => crahHandler(e.target,"c15","crateTemp")}
                        />
                    </li>
                </ul>

                <ul>
                    <li className="humidity1516">
                        Humidity
                    </li>

                    <li className="crate1516input">
                        <input
                            type="text"
                            value={crahState.c15.crateHumidity}
                            onChange={(e) => crahHandler(e.target,"c15","crateHumidity")}
                        />
                    </li>

                </ul>

            </div>

            <div id="crates1516">

                <ul>
                    <li>
                        CRAH
                    </li>
                    <li>
                        #16
                    </li>
                </ul>

                <ul>
                    <li>
                        Temp
                    </li>


                    <li className="crate1516input">
                        <input
                            type="text"
                            value={crahState.c16.crateTemp}
                            onChange={(e) => crahHandler(e.target,"c16","crateTemp")}
                        
                        />
                    </li>
                </ul>

                <ul>
                    <li className="humidity1516">
                        Humidity
                    </li>

                    <li className="crate1516input">
                        <input
                            type="text"
                            value={crahState.c16.crateHumidity}
                            onChange={(e) => crahHandler(e.target,"c16","crateHumidity")}  
                        />
                    </li>
                </ul>

            </div>

        </div>


        </div>

    )
}