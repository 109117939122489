import React from 'react';
import DigitalLogo from './images/DLR-7cf72d94.png'




export default function Header (){



    return (


        <div id="header">

            <div id='logohead'>
                <img src={DigitalLogo} />
                <h1> GoDigital</h1>
            </div>



        </div>
        

    

    )
}