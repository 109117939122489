import React from "react"

export default function DataRoomForm (props){

    const crateState = props.props3
    const setCrateState = props.handleClick4
    const pduState = props.props4
    const setpduState = props.handleClick5

    console.log(props.props2)


    const [dataState,setDataState] = React.useState(props.props)


    console.log(dataState)





    const dataRoomHandler = (event,subRoom,property) => {

        setDataState(prev => {
            const newupsState = { ...prev };
            newupsState[props.props2.currentDay][props.props2.currentSuite].dataRoom[subRoom][property] = event.value == undefined ? event : Number(event.value);
            return { ...prev, newupsState };
        })
    }


    const PDUHandler = (event,PDUNum,property) => {

        setDataState(prev => {
            const newupsState = { ...prev };
            newupsState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[PDUNum][property] = event.value == undefined ? event : Number(event.value);
            return { ...prev, newupsState };
        })
    }

    const crahHandler = (event,Crah,property) => {

        setDataState(prev => {
            const newupsState = { ...prev };
            newupsState[props.props2.currentDay][props.props2.currentSuite].dataRoom.crates[Crah][property] = event.value == undefined ? event : Number(event.value);
            return { ...prev, newupsState };
        })
    }




    function Daycoloum (day,props2,props3){
        
        const props1 = dataState;

        return (

        <div className="daycoloum">

            <div>
                {day[0].toUpperCase() + day[1] + day[2]}
            </div>
            <div>
                {props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].onStatus == true ? "ON":"OFF"}
            </div>
            <div>
                {props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].crateTemp}
            </div>
            <div>
                {props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].crateHumidity}
            </div>

        </div>

        )
    }

    function selectedDaycoloum (day,props2,props3){

        const props1 = dataState
  
        return(

            <div className="selecteddaycoluum">
                <div>
                    {day.charAt(0).toUpperCase() + day.slice(1)}  ⓘ
                </div>
                
                
                <div
                    className={props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].onStatus ? "switchon" : "switchoff"}
                    onClick={() => crahHandler(!props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].onStatus,("c" + props3),"onStatus")}
                >

                    {props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].onStatus === true ? "ON" : "OFF"}

                </div>
                
                

                <div className="datacratenumberinput">
                    <input 
                        type="text"
                        value={props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].onStatus === false ? 0 : props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].crateTemp}
                        onChange={(e) => crahHandler(e.target,("c" + props3),"crateTemp")}
                    />
                </div>

                
                <div className="datacratenumberinput">
                <input 
                    type="text"
                    value={props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].onStatus === false ? 0 : props1[day][props2.currentSuite].dataRoom.crates[("c" + props3)].crateHumidity}
                    onChange={(e) => crahHandler(e.target,("c" + props3),"crateHumidity")}
                />
                </div>
            
            </div>


        )
    }


    const dayCycleArray = ["monday","tuesday","wednesday","thursday","friday"]

    let mapOverDays = dayCycleArray.map(dayCycleArray => {
        if(props.props2.currentDay === dayCycleArray){
            return(selectedDaycoloum(dayCycleArray,props.props2,crateState))
        } else{
            return(Daycoloum(dayCycleArray,props.props2,crateState))
        }
    });


    function navButton (direction){
        if (crateState != 1 & direction == "left"){
            setCrateState(prev => (prev - 1))
        } else if(crateState != 14 & direction == "right"){
            setCrateState(prev => (prev + 1))
        }
    }




 



    return (

    <div id="dataRoomForm">

    <ul className="panelandroominspection">
        <li>
            <h3>Fire Panel Normal:</h3>
            <input
                type="checkbox"
                className="panelroominputs"

                checked={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.fireAndRoomInspection.isFirePanelNormal}
                onChange={() => dataRoomHandler(!dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.fireAndRoomInspection.isFirePanelNormal,"fireAndRoomInspection","isFirePanelNormal")}
                /> 

        </li>

        <li>
            <h3>Room Inspection:</h3>
            <input
                type="checkbox"
                className="panelroominputs"
                checked={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.fireAndRoomInspection.hasDoneRoomInspection}
                onChange={() => dataRoomHandler(!dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.fireAndRoomInspection.hasDoneRoomInspection,"fireAndRoomInspection","hasDoneRoomInspection")}
            /> 

        </li>
    </ul>
            
    <div id="cratesdiv">

        <div id="cratesnavbardiv">
            <div id="cratesnavbar">

                <h1 onClick={() => navButton("left")} > ◀ </h1> 
                <h1>— C{crateState - 1} — </h1>
                <input
                    type="number"
                    id="cratenumber"
                    min={1}
                    max={14}
                    

                    value={crateState}
                    onChange={(e) => setCrateState(Number(e.target.value >= 1 & e.target.value <= 14 ? e.target.value : 1 ))}
                    />
                <h1> — C{ Number(crateState) + 1} — </h1>
                <h1 onClick={() => navButton("right")} > ▶ </h1> 


            </div>
        </div>

        <div id="cratesweeklyinfo">
            
        <div id="cratesTitles">
            <div>
                Crah
            </div>
            <div>
                Status
            </div>
            <div>
                Temp
            </div>
            <div id="humidity">
                Humidity
            </div>

        </div>

        <div id="cratesday">

            {mapOverDays}

        </div>

        </div>

    </div>




    {props.props2.currentDay === "monday" && 
    
    <div id="PDUdiv">

        <div id="PDU">

            <div id="PDUtabs">
                <ul>
                    <li className={pduState[3] === "A" ? "selectedPDU" : "unselectedPDU"}
                    onClick={() => setpduState(prev =>{return Object.values({ ...prev, 3: "A" }).join('')})}
                    >
                        A
                    </li>

                    <li className={pduState[3] === "B" ? "selectedPDU" : "unselectedPDU"}
                    onClick={() => setpduState(prev =>{return Object.values({ ...prev, 3: "B" }).join('')})}
                    >
                        B
                    </li>
                </ul>
            </div>
    
            <div id="PDUchart">

                <ul id="PDUnumber">

                    <li 
                    className={pduState[4] === "1" ? "selectedPDUnumber" : "unselectedPDUnumber"}
                    onClick={() => setpduState(prev =>{return Object.values({ ...prev, 4: "1" }).join('')})}
                    >
                        1
                    </li>
                    <li
                    className={pduState[4] === "2" ? "selectedPDUnumber" : "unselectedPDUnumber"}
                    onClick={() => setpduState(prev =>{return Object.values({ ...prev, 4: "2" }).join('')})}
                    >                  
                        2
                    </li>
                    <li
                    className={pduState[4] === "3" ? "selectedPDUnumber" : "unselectedPDUnumber"}
                    onClick={() => setpduState(prev =>{return Object.values({ ...prev, 4: "3" }).join('')})}
                    >                   
                        3
                    </li>
                    <li 
                    className={pduState[4] === "4" ? "selectedPDUnumber" : "unselectedPDUnumber"}
                    onClick={() => setpduState(prev =>{return Object.values({ ...prev, 4: "4" }).join('')})}
                    >                   
                        4
                    </li>
                </ul>

                <div id="PDUtable">
                    <ul>
                        <li>
                            ⓘ
                        </li>
                        <li id="amps">
                            Amps
                        </li>
                        <li>
                            Kw
                        </li>
                        {/* <li>
                            Total
                        </li>

                        <li>
                            ALL
                        </li> */}
                    </ul>

                    <ul>
                        <li>
                            A
                        </li>

                        <input
                            type="text"
                            value={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].ampA}
                            onChange={(e) => PDUHandler(e.target,[pduState],"ampA")}
                            

                        />

                        <input
                            type="text"
                            value={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].kwA}
                            onChange={(e) => PDUHandler(e.target,[pduState],"kwA")}

                        />

                        {/* <li>
                            32
                        </li> */}
                    </ul>

                    <ul>
                        <li>
                            B
                        </li>
                        <input
                            type="text"
                            value={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].ampB}
                            onChange={(e) => PDUHandler(e.target,[pduState],"ampB")}

                        />
                        <input
                            type="text"
                            value={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].kwB}
                            onChange={(e) => PDUHandler(e.target,[pduState],"kwB")}

                        />

                        {/* <li>
                            433
                        </li> */}
                    </ul>

                    <ul>
                        <li>
                            C
                        </li>
                        <input
                            type="text"
                            value={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].ampC}
                            onChange={(e) => PDUHandler(e.target,[pduState],"ampC")}

                        />
                        <input
                            type="text"
                            value={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].kwC}
                            onChange={(e) => PDUHandler(e.target,[pduState],"kwC")}

                        />

                        {/* <li>
                            322
                        </li> */}
                    </ul>

                    <ul>
                        <li>
                            Total
                        </li>
                        <li>
                            -
                        </li>
                        <li>
                        {
                        
                        Number(dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].kwA) +
                        Number(dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].kwB) +
                        Number(dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].kwC) 

                        }kw

                        </li>

                        {/* <li>
                            23331Kw
                        </li> */}
                    </ul>

                    <div id="pdualarmphoto">

                        <div
                            className={dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].isthereAlarm === true ? "switchon" : "switchoff"}
                            onClick={() => PDUHandler(!dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].isthereAlarm,pduState,"isthereAlarm")}
                        >
                            Alarm {dataState[props.props2.currentDay][props.props2.currentSuite].dataRoom.PDU[pduState].isthereAlarm === true ? "ON" : "OFF"}
                        </div>

                    </div>

                </div>  

            </div>

        </div>

    </div>

    } 

                
    </div>

    )
}