import React from "react"

export default function GeneratorTab (props){
    
    const genArray = ["///","///","110","120.1","120.2","130","140","150","160","RG1","RG2","PBB","///","///"]

    const genState = props.props3
    const setGenState = props.handleClick2


    const [generator, setGenerator] = React.useState(props.props2)

    const genHandler = (event, genNum, property) => {
        setGenerator(prevState => {
            const newPumpState = { ...prevState };
            newPumpState[genNum][property] =
                event.value === undefined ? event : Number(event.value);
            return newPumpState;
        });
    };


    return(

        <div id="genPage">
    
            <div id="genNavPage">

                <div id="genNavDiv">
                    <div id="genNav">
                        <div
                        onClick={ genState > 2 ? () => setGenState(prev => (prev - 1)) : null }
                        >
                            <h1> ◀ </h1>
                        </div>

                        <div
                        onClick={genState > 3 ? () => setGenState(prev => (prev - 2)) : null}
                        >
                            <h4> {genArray[genState - 2 ]} </h4>
                        </div>

                        <div
                        onClick={ genState > 2 ? () => setGenState(prev => (prev - 1)) : null }
                        >
                            <h3> {genArray[genState - 1 ]} </h3>
                        </div>

                        <div>
                            <h1> <b> {genArray[genState]} </b> </h1>
                        </div>

                        <div 
                        onClick={genState < 11 ? () => setGenState(prev => (prev + 1)) : null}
                        >
                            <h3> {genArray[genState + 1 ]} </h3>
                        </div>

                        <div
                        onClick={genState < 10 ? () => setGenState(prev => (prev + 2)) : null}
                        >
                            <h4> {genArray[genState + 2 ]} </h4>
                        </div>

                        <div
                        onClick={genState < 11 ? () => setGenState(prev => (prev + 1)) : null}
                        >
                            <h1> ▶ </h1>
                        </div>

                    </div>

                    <div id="dataplategen">
                        <h1> ⓘ </h1>
                    </div>
                </div>
    
                <div id="genTable">

                    <div>
                        <div>
                            <div>
                            <p>Room Inspection:</p>
                            </div>
                            <div className="genInputDiv">
                                <input
                                    type="checkbox"
                                    className="genCheckbox"
                                    checked={generator[genArray[genState]].hasdoneroomInspection}
                                    onChange={() => genHandler(!generator[genArray[genState]].hasdoneroomInspection,genArray[genState],"hasdoneroomInspection")}
                                />
                            </div>

                        </div>

                        <div>
                            <div>
                            <p>Generator in Auto:</p>
                            </div>
                            <div className="genInputDiv">
                                <input
                                    type="checkbox"
                                    className="genCheckbox"
                                    checked={generator[genArray[genState]].isgeneratorInAuto}
                                    onChange={() => genHandler(!generator[genArray[genState]].isgeneratorInAuto,genArray[genState],"isgeneratorInAuto")}
                            
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div>
                                <p>Generator Block Temp:</p>
                            </div>
                            


                                {genArray[genState] != "PBB" &&
                                <div className="genInputDiv">
                                <input
                                    type="text"
                                    className="genNumberinput"
                                    value={generator[genArray[genState]].generatorBlockTemp}
                                    onChange={(e) => genHandler(e.target,genArray[genState],"generatorBlockTemp")}
                            
                                />
                                </div>
                                }

                                {genArray[genState] === "PBB" &&

                                <div>

                                    <div
                                    className={generator[genArray[genState]].generatorBlockTemp === true ? "switchon" : "switchoff"}
                                    onClick={() => genHandler(!generator[genArray[genState]].generatorBlockTemp,genArray[genState],"generatorBlockTemp")}
                                    >
                                        {generator[genArray[genState]].generatorBlockTemp ? "ON" : "OFF"}
                                    </div>



                                </div>
                                }



                            
                        </div>

                        <div>
                            <div>
                                <p>Generator Battery Voltage:</p>
                            </div>
                            <div className="genInputDiv">
                                <input
                                    type="text"
                                    className="genNumberinput"
                                    value={generator[genArray[genState]].generatorBatteryChargerVoltage}
                                    onChange={(e) => genHandler(e.target,genArray[genState],"generatorBatteryChargerVoltage")}
                            
                                />
                            </div>
                        </div>
                    </div>


                    {props.props === "monday" &&  

                    <div>
                        <div>
                            <div>
                                <p>Times Started:</p>
                            </div>
                            <div className="genInputDiv">
                                <input
                                    type="text"
                                    className="genNumberinput"
                                    value={generator[genArray[genState]].generatorStartCount}
                                    onChange={(e) => genHandler(e.target,genArray[genState],"generatorStartCount")}
                            
                                />
                            </div>
                        </div>

                        <div>
                            <div>
                                <p>Hours Ran:</p>
                            </div>
                            <div className="genInputDiv">
                                <input
                                    type="text"
                                    className="genNumberinput"
                                    value={generator[genArray[genState]].generatorTotalHoursRan}
                                    onChange={(e) => genHandler(e.target,genArray[genState],"generatorTotalHoursRan")}
                            
                                />
                            </div>
                        </div>
                    </div>
                    }

                    {props.props === "monday" && 
                    <div>
                        <div>
                            <div>
                                <p>Fuel Tank Levels:</p>
                            </div>

                            <div className="genInputDiv">
                            <input
                                type="text"
                                className="genNumberinput"
                                value={generator[genArray[genState]].tankFuelLevel}
                                onChange={(e) => genHandler(e.target,genArray[genState],"tankFuelLevel")}
                            
                            />
                            </div>
                        </div>

                        <div>
                            <div>
                            <p>Polisher working:</p>
                            </div>
                            <div className="genInputDiv">
                            <input
                                type="checkbox"
                                className="genCheckbox"
                                checked={generator[genArray[genState]].isFuelPolisherWorking}
                                onChange={() => genHandler(!generator[genArray[genState]].isFuelPolisherWorking,genArray[genState],"isFuelPolisherWorking")}
                            
                                
                            />
                            </div>
                        </div>
                    </div>
                    }
                    
                </div>

            </div>



        </div>
    )

}