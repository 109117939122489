import React from "react"




export default function RoomsTab (props){

    function MainRoom (){

        return(
            
        <div id="roomstab">

            { props.props.currentSuite != "yard" &&
            <div className={props.props.currentRoom === "pumpRoom" ? "activeRoom" : "inactiveRoom"} onClick={() => props.handleClick("pumpRoom","currentRoom")} >
            <h3>Pump Room</h3>
            </div>
            }

            { props.props.currentSuite != "yard" &&
            <div className={props.props.currentRoom === "dataRoom" ? "activeRoom" : "inactiveRoom"} onClick={() => props.handleClick("dataRoom","currentRoom")}>
                <h3>Data Room</h3>
            </div>
            }
    
            { props.props.currentSuite != "yard" &&
            <div className={props.props.currentRoom === "upsRoom" ? "activeRoom" : "inactiveRoom"} onClick={() => props.handleClick("upsRoom","currentRoom")}>
                <h3>UPS Room</h3>
            </div>
            }

            { props.props.currentSuite === "yard" &&
            <div className={props.props.currentRoom === "generator" ? "activeRoom" : "inactiveRoom"} onClick={() => props.handleClick("generator","currentRoom")} >
            <h3>Generator Yard</h3>
            </div>
            }

            { props.props.currentSuite === "yard" &&
            <div className={props.props.currentRoom === "chiller" ? "activeRoom" : "inactiveRoom"} onClick={() => props.handleClick("chiller","currentRoom")}>
                <h3>Chiller Yard</h3>
            </div>
            }

        </div>
        )
    }





    return(
        
        
        <MainRoom/>
    
    


    )

    }